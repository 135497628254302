import React from 'react'
import Box from '@mui/material/Box'

const Footer = () => {
  return (
    <Box
      sx={{
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
        height: '250px',
        position: 'relative',
        span: {
          position: 'absolute',
          bottom: '4rem',
        },
        a: {
          textDecoration: 'underline',
        },
      }}
    >
      <div className="Container"></div>
    </Box>
  )
}

export default Footer
