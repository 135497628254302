import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Divider from '@mui/material/Divider'
import { useTheme } from '@mui/material'
import GitHubIcon from '@mui/icons-material/GitHub'
import TvIcon from '@mui/icons-material/Tv'
import { GenerateRowStack } from '../../utils'

const SingleCard = ({ card }) => {
  const theme = useTheme()
  return (
    <Card
      elevation={4}
      sx={{
        display: 'flex',
        padding: 2,
        borderRadius: '16px',
        mb: '2rem',
        flexDirection: { xs: 'column', md: 'row' },
      }}
    >
      <CardContent sx={{ pr: 2 }}>
        <Box mb={1}>
          <Box
            component="h3"
            sx={{
              fontSize: 17,
              fontWeight: 'bold',
              letterSpacing: '0.5px',
              marginBottom: 0,
              marginRight: 1.5,
              display: 'inline-block',
            }}
          >
            {card.title}
          </Box>
        </Box>
        <CardMedia
          image={card.img}
          sx={{
            height: '150px',
            display: { xs: 'block', md: 'none' },
            maxWidth: '100%',
            backgroundColor: 'grey.200',
            borderRadius: '12px',
            boxShadow: '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9',
            mb: '1rem',
            mt: '1rem',
          }}
        />
        <Box
          component="p"
          sx={{ fontSize: 14, color: 'grey.500', mb: '1.275rem' }}
        >
          {card.description}
        </Box>
        <Divider sx={{ mt: 1, mb: 1, opacity: 0.6 }} />
        <Box>
          <GenerateRowStack
            title="Stacks"
            stacks={card.tech_stacks}
            titleSx={{
              mb: 1,
            }}
            boxSx={{
              justifyContent: 'flex-start',
            }}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              marginLeft: 'auto',
              gap: 1.5,
            }}
          >
            {card.github_url.length > 0 && (
              <Button
                variant="outlined"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.text.secondary,
                  '&:hover, &:focus': {
                    color: theme.palette.text.primary,
                    '& svg': {
                      opacity: 1,
                    },
                  },
                }}
                href={card.github_url}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<GitHubIcon />}
              >
                {'Github'}
              </Button>
            )}
            {card.demo_url.length > 0 && (
              <Button
                variant="outlined"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: theme.palette.text.secondary,
                  '&:hover, &:focus': {
                    color: theme.palette.text.primary,
                    '& svg': {
                      opacity: 1,
                    },
                  },
                }}
                href={card.demo_url}
                target="_blank"
                rel="noopener noreferrer"
                endIcon={<TvIcon />}
              >
                {'Demo'}
              </Button>
            )}
          </Box>
        </Box>
      </CardContent>
      <CardMedia
        image={card.img}
        sx={{
          minWidth: '40%',
          maxWidth: '50%',
          display: { xs: 'none', md: 'block' },
          flexShrink: 1,
          backgroundColor: 'grey.200',
          borderRadius: '12px',
          boxShadow: '0 2px 8px 0 #c1c9d7, 0 -2px 8px 0 #cce1e9',
        }}
      />
    </Card>
  )
}

SingleCard.propTypes = {
  card: PropTypes.object.isRequired,
}

export default SingleCard
