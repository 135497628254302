import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'
import SingleCard from './SingleCard'

import { projects } from '../../../data/projects'

const ProjectCard = () => {
  return (
    <>
      {projects.map((list, index) => (
        <ScrollAnimation animateIn="fadeInLeft" key={`projects-${index}`}>
          <SingleCard card={list} />
        </ScrollAnimation>
      ))}
    </>
  )
}

export default ProjectCard
