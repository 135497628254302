import { stacks } from './stacks'
export const languages = [
  {
    img: stacks.GO,
    name: 'GoLang',
    experience: 'Proficient',
  },
  {
    img: stacks.Gin,
    name: 'Gin',
    experience: 'Proficient',
    description: 'Go Web Framework',
  },
  {
    img: stacks.Rails,
    name: 'Ruby on Rails',
    experience: 'Proficient',
  },
  {
    img: stacks.NodeJs,
    name: 'NodeJS',
    experience: 'Intermediate',
  },
  {
    img: stacks.Express,
    name: 'Express',
    experience: 'Intermediate',
  },
  {
    img: stacks.ReactJS,
    name: 'ReactJS',
    experience: 'Intermediate',
  },
  {
    img: stacks.NextJs,
    name: 'NextJS',
    experience: 'Intermediate',
  },
  {
    img: stacks.Typescript,
    name: 'Typescript',
    experience: 'Intermediate',
  },
  {
    img: stacks.Python,
    name: 'Python',
    experience: 'Beginner',
  },
]

export const aws = [
  {
    img: stacks.DynamoDB,
    name: 'DynamoDB',
    experience: 'Proficient',
  },
  {
    img: stacks.SNS,
    name: 'SNS',
    experience: 'Proficient',
  },
  {
    img: stacks.SQS,
    name: 'SQS',
    experience: 'Proficient',
  },
  {
    img: stacks.Lambda,
    name: 'Lambda',
    experience: 'Proficient',
  },
  {
    img: stacks.S3,
    name: 'S3',
    experience: 'Proficient',
  },
  {
    img: stacks.RDS,
    name: 'RDS',
    experience: 'Proficient',
  },
  {
    img: stacks.CloudFormation,
    name: 'CloudFormation',
    experience: 'Intermediate',
  },
  {
    img: stacks.CodeBuild,
    name: 'CodeBuild',
    experience: 'Intermediate',
  },
]

export const databases = [
  {
    img: stacks.MongoDB,
    name: 'MongoDB',
    experience: 'Proficient',
  },
  {
    img: stacks.Postgres,
    name: 'Postgres',
    experience: 'Proficient',
  },
  {
    img: stacks.MySQL,
    name: 'My SQL',
    experience: 'Proficient',
  },
  {
    img: stacks.Redis,
    name: 'Redis',
    experience: 'Proficient',
  },
  {
    img: stacks.MemCached,
    name: 'MemCached',
    experience: 'Intermediate',
  },
  {
    img: stacks.ElasticSearch,
    name: 'ElasticSearch',
    experience: 'Intermediate',
  },
  {
    img: stacks.Snowflake,
    name: 'Snowflake',
    experience: 'Intermediate',
  },
]

export const devOps = [
  {
    img: stacks.Docker,
    name: 'Docker',
    experience: 'Proficient',
  },
  {
    img: stacks.Kubernetes,
    name: 'Kubernetes',
    experience: 'Intermediate',
  },
  {
    img: stacks.Helm,
    name: 'Helm',
    experience: 'Beginner',
  },
  {
    img: stacks.Grafana,
    name: 'Grafana',
    experience: 'Beginner',
  },
  {
    img: stacks.Jenkins,
    name: 'Jenkins',
    experience: 'Beginner',
  },
  {
    img: stacks.Prometheus,
    name: 'Prometheus',
    experience: 'Beginner',
  },
  {
    img: stacks.Git,
    name: 'Git',
    experience: 'Proficient',
  },
  {
    img: stacks.ECS,
    name: 'ECS',
    description: 'AWS Elastic Container Service',
    experience: 'Proficient',
  },
  {
    img: stacks.ECR,
    name: 'ECR',
    description: 'AWS Elastic Container Registry',
    experience: 'Proficient',
  },
]

export const tools = [
  {
    img: stacks.GitHub,
    name: 'GitHub',
    experience: 'Proficient',
  },
  {
    img: stacks.GitLab,
    name: 'GitLab',
    experience: 'Proficient',
  },
  {
    img: stacks.Jira,
    name: 'Jira',
    experience: 'Proficient',
  },
]
