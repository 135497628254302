import React from 'react'
import Box from '@mui/material/Box'
import { FaBlogger, FaGithub, FaLinkedinIn } from 'react-icons/fa'
import ScrollAnimation from 'react-animate-on-scroll'
const SocialIcons = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: '48%',
        right: '1.5rem',
        transform: 'translateY(-50%)',
        ul: {
          display: 'block',
        },
        '.item + .item': {
          marginTop: '1rem',
        },
        a: {
          fontSize: '2.3rem',
          color: 'rgb(119, 119, 121)',
          '&:hover': {
            color: 'rgb(57, 134, 250)',
          },
        },
        '@media screen and (max-width: 1000px)': {
          display: 'none',
        },
      }}
    >
      <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
        <ul>
          <li className="item">
            <a
              href="https://github.com/samit22"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaGithub />
            </a>
          </li>
          <li className="item">
            <a
              href="https://www.linkedin.com/in/samit22"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn />
            </a>
          </li>

          <li className="item">
            <a
              href="https://blog.samitghimire.com.np"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaBlogger />
            </a>
          </li>
        </ul>
      </ScrollAnimation>
    </Box>
  )
}

export default SocialIcons
