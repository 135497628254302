import { stacks } from './stacks'
export const projects = [
  {
    img: '/coup.jpg',
    title: 'The Coup Game',
    description:
      'Multiplayer Card game app that allows users to play the Coup game with friends.',
    tech_stacks: [
      {
        img: stacks.ReactJS,
        name: 'ReactJS',
        experience: '',
      },
      {
        img: stacks.Typescript,
        name: 'Typescript',
        experience: '',
      },
      {
        img: stacks.GO,
        name: 'Go',
        experience: '',
      },
      {
        img: stacks.Socket,
        name: 'Socket',
        experience: '',
      },
      {
        img: stacks.MongoDB,
        name: 'MongoDB',
        experience: '',
      },
    ],
    github_url: '',
    demo_url: '',
  },
  {
    img: '/calendarN.jpg',
    title: 'Nepali Calendar For Terminals',
    description:
      'A terminal app that displays Nepali calendar and date in the terminal with features like date conversion, countdown, etc.',
    tech_stacks: [
      {
        img: stacks.GO,
        name: 'Go',
        experience: '',
      },
      {
        img: stacks.Bash,
        name: 'CLI',
        experience: '',
      },
    ],
    github_url: 'https://github.com/samit22/calendarN',
    demo_url: '',
  },
  {
    img: '/covid.jpg',
    title: 'Covid Tracker for Nepal',
    description:
      'A Covid tracker app that displays the Covid data of Nepal in a graphical format. It also provides the Covid data of the world.',
    tech_stacks: [
      {
        img: stacks.ReactJS,
        name: 'ReactJS',
        experience: '',
      },
      {
        img: stacks.GO,
        name: 'Go',
        experience: '',
      },
      {
        img: stacks.MongoDB,
        name: 'MongoDB',
        experience: '',
      },
      {
        img: stacks.MapBox,
        name: 'MapBox',
        experience: '',
      },
    ],
    github_url: '',
    demo_url: '',
  },
  {
    img: '/dashain.jpg',
    title: 'Dashain Aayo',
    description:
      'Dashain is the biggest festival in Nepal. Everyone looks for the dates to book vacation and more, this app provides the dates.',
    tech_stacks: [
      {
        img: stacks.ReactJS,
        name: 'ReactJS',
        experience: '',
      },
      {
        img: stacks.HTML,
        name: 'HTML',
        experience: '',
      },
      {
        img: stacks.CSS,
        name: 'CSS',
        experience: '',
      },
    ],
    github_url: 'https://github.com/samit22/dashainaayo',
    demo_url: 'https://dashainaayo.com',
  },
]
