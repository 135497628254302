import React from 'react'
import Box from '@mui/material/Box'
import Link from '@mui/material/Link'
import Card from '@mui/material/Card'
import ScrollAnimation from 'react-animate-on-scroll'
import { BreakSection } from '../utils'

const About = () => {
  return (
    <>
      <BreakSection />
      <Box id="about">
        <div className="Container">
          <div className="sectionTitle">About Me</div>
          <Card
            elevation={1}
            sx={{ padding: 2, borderRadius: '16px', mb: '3rem' }}
          >
            <ScrollAnimation animateIn="fadeInLeft">
              <Box
                sx={{
                  maxWidth: '150px',
                  margin: '0 auto',
                  mb: '1rem',
                  borderRadius: '20px',
                }}
              >
                <img
                  src="/sampp.jpg"
                  alt="Samit Ghimire"
                  className="avatar-image"
                  height={150}
                  width={150}
                />
              </Box>
            </ScrollAnimation>
            <div className="AboutBio">
              <ScrollAnimation animateIn="fadeInLeft">
                Hello! My name is <strong>Samit Ghimire</strong>
                {`, a dedicated and innovative Software Engineer with
                extensive experience in the tech industry.
                Currently, I hold the position of Lead Engineer,
                where I have honed my skills in designing, developing,
                and optimizing complex software systems.
                My career has been marked by a passion for solving
                challenging problems and a knack for simplifying intricate processes.`}
              </ScrollAnimation>
              <br />

              <ScrollAnimation animateIn="fadeInLeft">
                {`As a Lead Engineer, I've successfully led numerous projects from
                conception to completion, consistently delivering high-quality
                software solutions. My ability to dissect complex issues and
                devise straightforward, effective solutions has earned me
                recognition and respect within my professional community. I also have written
                numerous articles on software development and programming which can be found on my `}
                <Link
                  href="https://blog.samitghimire.com.np"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong> blog.</strong>
                </Link>
              </ScrollAnimation>
              <br />

              <ScrollAnimation animateIn="fadeInLeft">
                {`When I'm not immersed in coding and algorithms, I pursue my
                passion for photography, capturing the world's beauty through my lens.
                Cycling is another of my favorite pastimes, providing a perfect blend of
                exercise and exploration. I also love to travel and experience new cultures and
                landscapes, which often inspire my work and personal projects which can be found on my `}
                <Link
                  href="https://instagram.com/samitclicks/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <strong>instagram page.</strong>
                </Link>
              </ScrollAnimation>
              <br />

              <ScrollAnimation animateIn="fadeInLeft">
                {`I believe in the power of simplicity and strive to
                 make complex concepts accessible and understandable.
                  Whether it's through leading a team, developing software,
                   or engaging in my hobbies, my goal is to bring clarity
                    and efficiency to everything I do.`}
              </ScrollAnimation>
            </div>
          </Card>
        </div>
      </Box>
    </>
  )
}

export default About
