import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import ScrollAnimation from 'react-animate-on-scroll'
import { FaXTwitter } from 'react-icons/fa6'
import axios from 'axios'
import {
  FaInstagram,
  FaBlogger,
  FaFacebook,
  FaGithub,
  FaLinkedinIn,
} from 'react-icons/fa'
import { BreakSection } from '../utils'
import EmailIcon from '@mui/icons-material/Email'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import Loader from './Loader'
import ReCAPTCHA from 'react-google-recaptcha'
import useRecaptcha from '../Captcha/Recaptcha'
import SendIcon from '@mui/icons-material/Send'

const Contact = ({ addAlert, darkTheme }) => {
  const { captchaToken, recaptchaRef, handleRecaptcha } = useRecaptcha()

  const SocialLink = ({ tooltip, link, icon }) => {
    return (
      <Tooltip title={tooltip} arrow>
        <IconButton
          size="large"
          variant="contained"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          sx={{
            textTransform: 'Capitalize',
          }}
        >
          {icon}
        </IconButton>
      </Tooltip>
    )
  }
  SocialLink.propTypes = {
    tooltip: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
  }
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    captcha_token: '',
  })
  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }
  const handleOpen = () => {
    setOpen(true)
  }
  function onFormSubmit(e) {
    e.preventDefault()
    handleOpen()
    if (!captchaToken) {
      addAlert('Please verify that you are not a robot.', 'error')
      handleClose()
      return
    }
    if (formData.email.length < 1 || !formData.email.includes('@')) {
      addAlert('Please enter a valid email.', 'error')
      handleClose()
      return
    }
    formData.captcha_token = captchaToken
    axios
      .post('https://contact-form.samit.workers.dev', formData)
      .then(res => {
        addAlert('Form submitted successfully', 'success')
        console.log('Form submitted successfully', res)
      })
      .catch(err => {
        addAlert('Error Submitting From.', 'error')
        console.error('Error submitting form', err)
      })
      .finally(() => {
        handleClose()
        recaptchaRef.current?.reset()
        setFormData({ name: '', email: '', message: '', captcha_token: '' })
      })
  }
  const isButtonDisabled =
    formData.email.length < 1 ||
    formData.message.length < 10 ||
    !formData.email.includes('@') ||
    captchaToken.length < 1

  return (
    <>
      <BreakSection />
      <Loader open={open} handleClose={handleClose} />
      <Box id="contact">
        <div className="Container">
          <div className="sectionTitle">Contact</div>
          <Card
            sx={{
              mb: '2rem',
            }}
          >
            <ScrollAnimation animateIn="fadeInLeft">
              <Box
                component="div"
                sx={{
                  m: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                  '& .MuiTextField-root': { m: 2, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  type="email"
                  id="contact-msg-email"
                  label="Email"
                  placeholder="Enter your email."
                  variant="standard"
                  required
                  value={formData.email}
                  onChange={e =>
                    setFormData({ ...formData, email: e.target.value })
                  }
                  error={
                    formData.email.length > 1 && !formData.email.includes('@')
                  }
                />
                <TextField
                  type="text"
                  id="contact-msg-name"
                  label="Name"
                  placeholder="Enter your name."
                  variant="standard"
                  value={formData.name}
                  onChange={e =>
                    setFormData({ ...formData, name: e.target.value })
                  }
                />
                <TextField
                  id="contact-msg"
                  label="Message"
                  placeholder="Your message, feedback, query, or anything you want to say. Minimum 10 characters."
                  multiline
                  minRows={4}
                  maxRows={10}
                  variant="standard"
                  required
                  value={formData.message}
                  error={
                    formData.email.length > 1 && formData.message.length < 10
                  }
                  onChange={e =>
                    setFormData({ ...formData, message: e.target.value })
                  }
                />
                <Box>
                  <ReCAPTCHA
                    theme={darkTheme ? 'dark' : 'light'}
                    name={'recaptcha'}
                    ref={recaptchaRef}
                    sitekey="6LcX4VgqAAAAAHGBDOztIx5Pw0ngtZiB-z9ee5Wj"
                    onChange={handleRecaptcha}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      m: 2,
                      alignSelf: 'right',
                      textTransform: 'Capitalize',
                      color: darkTheme ? '#111' : '#000',
                      backgroundColor: theme => {
                        return theme.palette.primary.main
                      },
                      '&:hover, &:focus': {
                        color: theme => {
                          return theme.palette.text.secondary
                        },
                      },
                    }}
                    disabled={isButtonDisabled}
                    onClick={onFormSubmit}
                    endIcon={<SendIcon />}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeIn">
              <Box
                sx={{
                  m: 2,
                }}
              >
                <Typography
                  component={'div'}
                  sx={{
                    fontSize: '1.5rem',
                    mb: '0.5rem',
                    textAlign: 'center',
                  }}
                >
                  Socials
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '2rem',
                  }}
                >
                  <SocialLink
                    tooltip="Send email: samitghimire@gmail.com"
                    link="mailto:samitghimire@gmail.com"
                    icon={<EmailIcon />}
                  />
                  <SocialLink
                    tooltip="LinkedIn"
                    link="https://www.linkedin.com/in/samit22"
                    icon={<FaLinkedinIn />}
                  />

                  <SocialLink
                    tooltip="Github"
                    link="https://github.com/samit22"
                    icon={<FaGithub />}
                  />
                  <SocialLink
                    tooltip="Blogs"
                    link="https://blog.samitghimire.com.np"
                    icon={<FaBlogger />}
                  />
                  <SocialLink
                    tooltip="X (formerly twitter)"
                    link="https://x.com/samit_gh"
                    icon={<FaXTwitter />}
                  />
                  <SocialLink
                    tooltip="Instagram"
                    link="https://www.instagram.com/samitgh"
                    icon={<FaInstagram />}
                  />
                  <SocialLink
                    tooltip="Facebook"
                    link="https://www.facebook.com/ghimiresamit"
                    icon={<FaFacebook />}
                  />
                </Box>
              </Box>
            </ScrollAnimation>
          </Card>
        </div>
      </Box>
    </>
  )
}

Contact.propTypes = {
  addAlert: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool.isRequired,
}

export default Contact
