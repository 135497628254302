import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

export default function Loader({ open }) {
  return (
    <div>
      <Backdrop
        sx={{
          color: theme => theme.palette.primary,
          zIndex: theme => theme.zIndex.drawer + 1,
        }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  )
}

Loader.propTypes = {
  open: PropTypes.bool.isRequired,
}
