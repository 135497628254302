import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import NavBar from '../Header/Header'
import MouseIcon from '@mui/icons-material/Mouse'
import { TypeAnimation } from 'react-type-animation'
import ScrollAnimation from 'react-animate-on-scroll'

import { ScrollDown } from './HomeElements'

const HomeC = ({ toggleDarkTheme, darkTheme }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [showSubtitle, setShowSubtitle] = useState(false)
  const [showScrollDown, setShowScrollDown] = useState(false)

  const toggle = () => {
    setIsOpen(!isOpen)
  }

  return (
    <Box
      sx={{
        backgroundColor: darkTheme ? '#121212' : '#f6f6f6',
        backgroundImage: darkTheme ? 'url(/bg-black.svg)' : 'none',
        color: darkTheme ? '#f6f6f6' : '#121212',
        transition: 'all 0.25s linear',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
      id={'home'}
    >
      <NavBar
        toggle={toggle}
        toggleDarkTheme={toggleDarkTheme}
        darkTheme={darkTheme}
      />
      <Container
        sx={{
          pb: '2rem',
          pt: '4rem',
          pr: '1rem',
          pl: '1rem',
          mr: 'auto',
          ml: 'auto',
          display: 'flex',
          flexDirection: 'column',
          maxWidth: {
            xs: '540px',
            sm: '720px',
            md: '960px',
            lg: '1000px',
          },
        }}
      >
        <Box
          component={'div'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            '@media screen and (max-width: 992px)': {
              flexDirection: 'column',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              flex: 1,
              '& h1': {
                fontSize: '2.5rem',
                fontWeight: 400,
              },
              '& h5': {
                fontSize: '1.5rem',
                color: 'rgb(119, 119, 121)',
                marginBottom: '1rem',
                fontWeight: 400,
              },
              '@media screen and (max-width: 992px)': {
                alignItems: 'center',
                marginBottom: '1rem',
                textAlign: 'center',
                '& h5': {
                  minHeight: '5rem',
                },
              },
            }}
          >
            <TypeAnimation
              cursor={false}
              sequence={['Hello, I am Samit. 👋', () => setShowSubtitle(true)]}
              speed={{ type: 'keyStrokeDelayInMs', value: 150 }}
              wrapper="h1"
              repeat={0}
            />
            {showSubtitle && (
              <TypeAnimation
                cursor={true}
                sequence={[
                  500,
                  'A Software Engineer. 🤓',
                  1000,
                  'I turn coffee into code.',
                  2000,
                  'I make complex simple.',
                  1500,
                  'A problem solver.',
                  1000,
                  'Hmmmm.....',
                  1000,
                  'What else...',
                  1000,
                  'I love Photography! 📸',
                  1000,
                  'Hmm.... what else 🤔',
                  1000,
                  'And, I love to travel! 🌍',
                  300,
                  () => setShowScrollDown(true),
                  1000,
                  "Ok, I think that's enough about me.",
                  1000,
                  'Wait.....',
                  1000,
                  'You are still here?',
                  1000,
                  'Scroll down to know more about me! 😄',
                  2000,
                  "Aright, I'm gonna restart the loop now...",
                  1000,
                  'Thanks for stopping by!',
                  500,
                ]}
                speed={50}
                deletionSpeed={65}
                wrapper="h5"
                repeat={Infinity}
              />
            )}
          </Box>
          <Box
            sx={{
              flex: 1,
              justifyContent: 'center',
              display: 'flex',
              '@media screen and (max-width: 992px)': {
                marginTop: '0.5rem',
              },
            }}
          >
            <ScrollAnimation animateIn="fadeIn">
              <img
                height={400}
                width="auto"
                src="/sam-avatar-1.png"
                alt="Sam Avatar"
                className="avatar-image"
              />
            </ScrollAnimation>
          </Box>
        </Box>
        {showScrollDown && (
          <ScrollAnimation animateIn="flipInX" offset={3}>
            <ScrollDown to="timeline" id="scrollDown">
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontSize: '1rem',
                  cursor: 'pointer',
                }}
              >
                Scroll For More..
                <MouseIcon
                  sx={{
                    height: '24px',
                    width: '24px',
                    marginLeft: '0.5rem',
                  }}
                />
              </Box>
            </ScrollDown>
          </ScrollAnimation>
        )}
      </Container>
    </Box>
  )
}

HomeC.propTypes = {
  toggleDarkTheme: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool.isRequired,
}

export default HomeC
