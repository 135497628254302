import React from 'react'
import PropTypes from 'prop-types'
import HomeC from '../components/Home/Home'
import Projects from '../components/Projects/Projects'
import About from '../components/About/About'
import Contact from '../components/Contact/Contact'
import Footer from '../components/Footer/Footer'
import SocialIcons from '../components/Socials/Icons'
import Scroll from '../components/Socials/Scroll'
import Timeline from '../components/Timeline/Timeline'
import MyStacks from '../components/Stacks/Stack'

import { useSnackbar } from 'notistack'

const Home = ({ toggleDarkTheme, darkTheme }) => {
  const { enqueueSnackbar } = useSnackbar()

  function addAlert(message, variant) {
    enqueueSnackbar(message, { variant })
  }

  return (
    <>
      <HomeC darkTheme={darkTheme} toggleDarkTheme={toggleDarkTheme} />
      <Timeline />
      <Projects />
      <MyStacks />
      <About />
      <Contact addAlert={addAlert} darkTheme={darkTheme} />
      <SocialIcons />
      <Footer />
      <Scroll />
    </>
  )
}
Home.propTypes = {
  toggleDarkTheme: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool.isRequired,
}

export default Home
