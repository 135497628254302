const url = 'https://raw.githubusercontent.com/devicons/devicon/master/icons'

const awsURL = 'https://raw.githubusercontent.com/samit22/dev-icons-svg/main'

export const stacks = {
  GO: `${url}/go/go-original.svg`,
  Gin: `https://raw.githubusercontent.com/gin-gonic/logo/master/color.svg`,
  Ruby: `${url}/ruby/ruby-original.svg`,
  Rails: `${url}/rails/rails-original-wordmark.svg`,
  ReactJS: `${url}/react/react-original.svg`,
  NextJs: `${url}/nextjs/nextjs-original.svg`,
  Express: `${url}/express/express-original.svg`,
  Typescript: `${url}/typescript/typescript-original.svg`,
  NodeJs: `${url}/nodejs/nodejs-original-wordmark.svg`,
  MongoDB: `${url}/mongodb/mongodb-original-wordmark.svg`,
  Postgres: `${url}/postgresql/postgresql-original-wordmark.svg`,
  Redis: `${url}/redis/redis-original.svg`,
  MySQL: `${url}/mysql/mysql-original.svg`,
  MemCached: `${awsURL}/databases/memcached/original.svg`,
  Python: `${url}/python/python-original.svg`,
  AWS: `${url}/amazonwebservices/amazonwebservices-original-wordmark.svg`,
  DynamoDB: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_Database/64/Arch_Amazon-DynamoDB_64.svg`,
  SNS: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_App-Integration/64/Arch_Amazon-Simple-Notification-Service_64.svg`,
  SQS: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_App-Integration/64/Arch_Amazon-Simple-Queue-Service_64.svg`,
  Lambda: `${awsURL}/q1-2022/Architecture-Service-Icons_01312022/Arch_Compute/48/Arch_AWS-Lambda_48.svg`,
  S3: `${awsURL}/q1-2022/Resource-Icons_01312022/Res_Storage/Res_48_Dark/Res_Amazon-Simple-Storage-Service_Bucket_48_Dark.svg`,
  ElasticSearch: `${url}/elasticsearch/elasticsearch-original.svg`,
  CloudFront: `${url}/cloudfront/cloudfront-original.svg`,
  Route53: `${url}/route53/route53-original-wordmark.svg`,
  RDS: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_Database/64/Arch_Amazon-RDS_64.svg`,
  CloudFormation: `${awsURL}/q1-2022/Architecture-Service-Icons_01312022/Arch_Management-Governance/32/Arch_AWS-CloudFormation_32.svg`,
  CodeBuild: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_Developer-Tools/64/Arch_AWS-CodeBuild_64.svg`,
  ECS: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_Containers/64/Arch_Amazon-Elastic-Container-Service_64.svg`,
  ECR: `${awsURL}/aws-2024-08/Architecture-Service-Icons_06072024/Arch_Containers/64/Arch_Amazon-Elastic-Container-Registry_64.svg`,
  HTML: `${url}/html5/html5-original-wordmark.svg`,
  CSS: `${url}/css3/css3-original-wordmark.svg`,
  JavaScript: `${url}/javascript/javascript-original.svg`,

  Socket: `${url}/socketio/socketio-original.svg`,
  Bash: `${url}/bash/bash-original.svg`,
  MapBox: `https://raw.githubusercontent.com/mapbox/assembly/publisher-staging/src/svgs/mapbox.svg`,

  GitHub: `${url}/github/github-original.svg`,
  Jira: `${url}/jira/jira-original-wordmark.svg`,
  GitLab: `${url}/gitlab/gitlab-original-wordmark.svg`,

  Git: `${url}/git/git-original.svg`,
  Docker: `${url}/docker/docker-original-wordmark.svg`,
  Kubernetes: `${url}/kubernetes/kubernetes-original.svg`,
  Helm: `${url}/helm/helm-original.svg`,
  Grafana: `${url}/grafana/grafana-original.svg`,
  Jenkins: `${url}/jenkins/jenkins-original.svg`,
  Prometheus: `${url}/prometheus/prometheus-original-wordmark.svg`,

  Snowflake: `/logos/snowflake.svg`,
}
