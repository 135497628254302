export const navLinks = [
  {
    id: 'header-timeline',
    url: 'timeline',
    text: 'Experience',
  },
  {
    id: 'header-projects',
    url: 'projects',
    text: 'Projects',
  },
  {
    id: 'header-stacks',
    url: 'stacks',
    text: 'Stacks',
  },
  {
    id: 'header-about',
    url: 'about',
    text: 'About',
  },
  // {
  //   id: 'header-resume',
  //   url: 'resume',
  //   text: 'Resume',
  // },
  {
    id: 'header-contact',
    url: 'contact',
    text: 'Contact',
  },
]
