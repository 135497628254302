import React from 'react'
import ProjectCard from './ProjectCard/ProjectCard'
import { BreakSection } from '../utils'

const Projects = () => {
  return (
    <>
      <BreakSection />
      <div className="projectWrapper" id="projects">
        <div className="Container">
          <div className="sectionTitle">Projects</div>
          <ProjectCard />
        </div>
      </div>
    </>
  )
}

export default Projects
