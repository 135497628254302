import React from 'react'
import PropTypes from 'prop-types'
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import { FaLaptopCode, FaStar, FaHome } from 'react-icons/fa'
import { useTheme } from '@mui/material'
import { BreakSection } from '../utils'

const Timeline = () => {
  const theme = useTheme()
  const isDark = theme.palette.mode === 'dark'
  const Element = ({ title, subtitle, date, content, icon, bg }) => {
    return (
      <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date={date}
        iconStyle={{
          background: isDark ? '#454b50' : '#f5f5f5',
          color: isDark ? theme.palette.text.light : theme.palette.text.dark,
        }}
        icon={icon}
        contentStyle={{
          background: bg ? bg : theme.palette.background.paper,
          color: theme.palette.text.primary,
          boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
          backgroundImage:
            'linear-gradient(rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09))',
        }}
        contentArrowStyle={{
          borderRight: `7px solid ${isDark ? '#f5f5f5' : '#454b50'}`,
        }}
      >
        <h3 className="vertical-timeline-element-title">{title}</h3>
        <h4 className="vertical-timeline-element-subtitle">{subtitle}</h4>
        <p>{content}</p>
      </VerticalTimelineElement>
    )
  }

  Element.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    bg: PropTypes.string,
  }

  return (
    <>
      <BreakSection />
      <div className="timelineWrapper" id="timeline">
        <div className="Container">
          <div className="sectionTitle">Experience</div>

          <VerticalTimeline
            animate={true}
            layout="2-columns"
            lineColor={isDark ? '#454b50' : '#f5f5f5'}
          >
            <Element
              title="Lead Engineer"
              subtitle="CloudFactory"
              date="2022 - present"
              content="Responsible for leading the development and successful delivery of solutions and building team."
              icon={<FaLaptopCode />}
            />
            <Element
              title="Sr. Software Engineer"
              subtitle="CloudFactory"
              date="2020 - 2022"
              content="Responsible for designing and implementing domain/team-level technical solutions and mentor other engineers."
              icon={<FaLaptopCode />}
            />
            <Element
              title="Software Engineer"
              subtitle="CloudFactory"
              date="2017 - 2020"
              content="Responsible to build quality software in a timely fashion adhering engineering best practices."
              icon={<FaLaptopCode />}
            />
            <Element
              title="Jr. Software Engineer"
              subtitle="CloudFactory"
              date="2016 - 2017"
              content="Responsible for implementing and maintaining scoped product and system features."
              icon={<FaLaptopCode />}
            />
            <Element
              title="Freelance Developer"
              subtitle="Independent"
              date="2014-2016"
              content="Designed and developed websites and web applications for clients in Nepal."
              icon={<FaHome />}
            />
            <VerticalTimelineElement
              iconStyle={{ background: isDark ? '#454b50' : '#f5f5f5' }}
              icon={<FaStar />}
            />
          </VerticalTimeline>
        </div>
      </div>
    </>
  )
}

export default Timeline
