import * as React from 'react'
import PropTypes from 'prop-types'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import useScrollTrigger from '@mui/material/useScrollTrigger'
import Slide from '@mui/material/Slide'
import { Typography, useTheme } from '@mui/material'
import { navLinks } from '../../data/navLinks'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import LightModeRoundedIcon from '@mui/icons-material/LightModeRounded'
import DarkModeSharpIcon from '@mui/icons-material/DarkModeSharp'
import MenuItem from '@mui/material/MenuItem'
import Menu from '@mui/material/Menu'
import MenuIcon from '@mui/icons-material/Menu'

import { Link as ScrollLink } from 'react-scroll'

function HideOnScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  })

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

export default function NavBar(props) {
  const { toggleDarkTheme, darkTheme } = props
  const theme = useTheme()
  const [anchorElNav, setAnchorElNav] = React.useState(null)
  const handleOpenNavMenu = event => {
    setAnchorElNav(event.currentTarget)
  }
  const handleCloseNavMenu = () => {
    setAnchorElNav(null)
  }

  return (
    <HideOnScroll {...props}>
      <AppBar
        sx={{
          backgroundColor: theme.palette.mode == 'dark' ? '#121212' : '#f6f6f6',
          color: theme.palette.text.primary,
          transition: 'all 0.25s linear',
        }}
        elevation={0}
        enableColorOnDark
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Box
              sx={{
                flexGrow: 1,
                mr: 2,
                '& img': {
                  width: '47px',
                  height: '47px',
                },
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <ScrollLink to={'home'}>
                <img src="/logo.png" alt="logo" />
              </ScrollLink>
            </Box>
            {/* For Mobile View */}
            <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
              <Tooltip title={`Toggle ${darkTheme ? 'Light' : 'Dark'} Mode`}>
                <IconButton onClick={toggleDarkTheme} color="inherit">
                  {darkTheme ? <DarkModeSharpIcon /> : <LightModeRoundedIcon />}
                </IconButton>
              </Tooltip>
              <IconButton
                size="large"
                aria-label="Menu"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-xs"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {navLinks.map(page => (
                  <MenuItem key={page.text} onClick={handleCloseNavMenu}>
                    <ScrollLink to={page.url}>{page.text}</ScrollLink>
                  </MenuItem>
                ))}
              </Menu>
            </Box>

            <Box
              sx={{
                flexGrow: 2,
                justifyContent: 'center',
                display: { xs: 'none', md: 'flex' },
              }}
            >
              {navLinks.map(page => (
                <Typography
                  underline="none"
                  type="link"
                  key={page.text}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 2,
                    color: theme.palette.text.primary,
                    display: 'block',
                    textTransform: 'capitalize',
                    fontSize: '1rem',
                    pr: 2,
                    '&:hover': {
                      fontWeight: 'bold',
                      cursor: 'pointer',
                    },
                  }}
                >
                  <ScrollLink to={page.url}>{page.text}</ScrollLink>
                </Typography>
              ))}
            </Box>
            <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
              <Tooltip title={`Toggle ${darkTheme ? 'Light' : 'Dark'} Mode`}>
                <IconButton onClick={toggleDarkTheme} color="inherit">
                  {darkTheme ? <DarkModeSharpIcon /> : <LightModeRoundedIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </HideOnScroll>
  )
}

NavBar.propTypes = {
  toggle: PropTypes.func.isRequired,
  toggleDarkTheme: PropTypes.func.isRequired,
  darkTheme: PropTypes.bool.isRequired,
}
