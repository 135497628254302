import React from 'react'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import ScrollAnimation from 'react-animate-on-scroll'
import Tooltip from '@mui/material/Tooltip'

const EachStack = ({ img, name, experience, description }) => {
  return (
    <ScrollAnimation animateIn="fadeInLeft">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100px',
          minWidth: '60px',
          marginBottom: '2rem',
        }}
        className="tech"
      >
        <Tooltip title={description || name} placement="top">
          <img
            src={img}
            alt={name}
            style={{
              height: '30px',
              width: '30px',
            }}
          />
        </Tooltip>
        <Typography
          component={'p'}
          sx={{
            fontSize: '12px',
          }}
        >
          {name}
        </Typography>
        {experience.length > 0 && (
          <Typography
            sx={{
              fontSize: '8px',
            }}
          >
            {experience}
          </Typography>
        )}
      </Box>
    </ScrollAnimation>
  )
}
EachStack.propTypes = {
  img: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export const GenerateRowStack = ({ title, stacks, titleSx, boxSx }) => {
  const boxStyle = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: boxSx?.alignItems || 'center',
    justifyContent: boxSx?.justifyContent || 'center',
    gap: '2rem',
    flexWrap: { xs: 'wrap', md: 'nowrap' },
  }
  return (
    <>
      <Typography component={'div'} sx={titleSx}>
        {title}
      </Typography>
      <Box component={'div'} sx={boxStyle}>
        {stacks.map((stack, index) => (
          <EachStack
            key={stack.name + index}
            img={stack.img}
            name={stack.name}
            experience={stack.experience}
            description={stack.description}
          />
        ))}
      </Box>
    </>
  )
}
GenerateRowStack.propTypes = {
  title: PropTypes.any.isRequired,
  stacks: PropTypes.array.isRequired,
  titleSx: PropTypes.object,
  boxSx: PropTypes.object,
}
