import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './App.css'
import Home from './routes/Home'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import { SnackbarProvider } from 'notistack'
import { ErrorBoundary } from 'react-error-boundary'

import ReactGA from 'react-ga4'
const gTag = process.env.REACT_APP_G_TAG

const App = () => {
  ReactGA.initialize(gTag)
  const systemDark = useMediaQuery('(prefers-color-scheme: dark)')
  const [darkMode, setDarkMode] = useState(systemDark)

  const toggleDarkTheme = () => {
    setDarkMode(!darkMode)
  }

  const darkTheme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light', // handle the dark mode state on toggle
      primary: {
        main: '#90caf9',
      },
      secondary: {
        main: '#131052',
      },
    },
  })

  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre>{error.message}</pre>
        console.log(error)
        <button onClick={resetErrorBoundary}>Try again</button>
      </div>
    )
  }
  ErrorFallback.propTypes = {
    error: PropTypes.object.isRequired,
    resetErrorBoundary: PropTypes.func.isRequired,
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <ErrorBoundary
        fallbackRender={ErrorFallback}
        onReset={() => {
          // Reset the state of your app so the error doesn't happen again
        }}
      >
        <SnackbarProvider
          preventDuplicate
          maxSnack={3}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Home darkTheme={darkMode} toggleDarkTheme={toggleDarkTheme} />
        </SnackbarProvider>
      </ErrorBoundary>
    </ThemeProvider>
  )
}

export default App
