import React from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import { BreakSection } from '../utils'
import { GenerateRowStack } from '../utils'
import { stacks } from '../..//data/stacks'

import { languages, aws, databases, tools, devOps } from '../../data/skills'

const MyStacks = () => {
  const titleSX = {
    display: 'flex',
    fontWeight: 400,
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  }
  return (
    <>
      <BreakSection />
      <Box id="stacks">
        <div className="Container">
          <div className="sectionTitle">Stacks</div>
          <Card
            elevation={2}
            sx={{
              padding: 2,
              borderRadius: '16px',
              mb: '3rem',
              display: 'flex',
              flexDirection: 'column',
              flexWrap: 'wrap',
              justifyContent: 'center',
              marginRight: 'auto',
              marginLeft: 'auto',
              gap: '1rem',
            }}
          >
            <GenerateRowStack
              title="Language and Frameworks"
              stacks={languages}
              titleSx={titleSX}
            />
            <GenerateRowStack
              title={
                <>
                  <img src={stacks.AWS} height="30px" width="30px" />
                  Services
                </>
              }
              stacks={aws}
              titleSx={titleSX}
            />
            <GenerateRowStack
              title="Databases"
              stacks={databases}
              titleSx={titleSX}
            />
            <GenerateRowStack
              title="DevOps Tools"
              stacks={devOps}
              titleSx={titleSX}
            />
            <GenerateRowStack title="Tools" stacks={tools} titleSx={titleSX} />
          </Card>
        </div>
      </Box>
    </>
  )
}

export default MyStacks
